<template>
  <v-card class="mb-5">
    <v-card-text class="pt-1">
      <custom-snackbar ref="snackbar"/>

      <v-toolbar flat class="d-flex flex-row-reverse">
        <v-col class="py-0 col-4 pr-4 col-lg-4 col-sm-4">
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  block
                  color="info"
                  class="v-btn--height-fix"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogImport = true"
              >
                <v-icon>
                  mdi-upload
                </v-icon>
              </v-btn>
            </template>
            <span>Importar Tabela de Fretes</span>
          </v-tooltip>
        </v-col>

        <v-col class="py-0 col-4 pr-4 col-lg-4 col-sm-4">
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  dark
                  block
                  color="info"
                  class="v-btn--height-fix"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportFile"
              >
                <v-icon>
                  mdi-printer
                </v-icon>
              </v-btn>
            </template>
            <span>Exportar Tabela de Fretes</span>
          </v-tooltip>
        </v-col>

        <v-col class="py-0 col-4 pr-0 col-lg-4 col-sm-4">
          <v-btn
              outlined
              block
              color="success"
              class="v-btn--height-fix"
              @click="dialogForm = true"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>
      <!-- Filtros -->
      <v-row class="text-sm-body-1 mt-5 mb-0">
        <v-col class="py-0 col-12 col-lg-3">
          <EmpresasSelect v-model="empresaFilter" mostra-todos-label @input="fieldsSelected"></EmpresasSelect>
        </v-col>

        <v-col class="py-0 col-12 col-lg-2">
          <ProducoesSelect v-model="producaoFilter" :empresa="empresaFilter" multiple mostra-todos-label @input="fieldsSelected"></ProducoesSelect>
        </v-col>

        <v-col class="py-0 col-12 col-lg-2">
          <DatePicker label="Início Vigência" v-model="inicioVigenciaFilter" @input="fieldsSelected"/>
        </v-col>

        <v-col class="py-0 col-12 col-lg-2">
          <DatePicker label="Fim Vigência" v-model="fimVigenciaFilter" @input="fieldsSelected"/>
        </v-col>

        <v-col class="py-0 col-12 col-lg-3">
          <EstadoSelect v-model="estadoFilter" mostra-todos-label @input="fieldsSelected"/>
        </v-col>

        <v-col class="py-0 col-12 col-lg-5">
          <CidadeSelect v-model="cidadeFilter" :estado="estadoFilter" mostra-todos-label @input="fieldsSelected"/>
        </v-col>

        <v-col class="py-0 col-12 col-lg-2">
          <v-text-field
              dense
              outlined
              label="Distância Mín (km)"
              v-model="distanciaMinFilter"
              type="number"
              @keydown="
              $event.key === '-' ||
              $event.key === 'e' ||
              $event.key === '.' ||
              $event.key === ',' ?
              $event.preventDefault() : null"
              @input="fieldsSelected"
          />
        </v-col>

        <v-col class="py-0 col-12 col-lg-2">
          <v-text-field
              dense
              outlined
              label="Distância Máx (km)"
              v-model="distanciaMaxFilter"
              type="number"
              @keydown="
              $event.key === '-' ||
              $event.key === 'e' ||
              $event.key === '.' ||
              $event.key === ',' ?
              $event.preventDefault() : null"
              @input="fieldsSelected"
          />
        </v-col>

        <v-col class="py-0 col-12 col-lg-2">
          <v-select
              dense
              outlined
              label="Status"
              item-text="nome"
              item-value="status"
              v-model="statusFilter"
              :items="statusOptionsFilter"
              @change="fieldsSelected"
          />
        </v-col>

        <v-col class="py-0 col-12 col-md-1">
          <v-tooltip
              top
              v-model="flagBuscaPendente"
              color="warning"
              v-on="on"
              max-width="139px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  outlined
                  block
                  color="info"
                  class="v-btn--height-fix"
                  @click="getTabelaFretes"
              >
                <v-icon dark>
                  mdi-cached
                </v-icon>
              </v-btn>
            </template>
            <span>Busca pendente</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <!-- Dados -->
      <v-data-table
          dense
          :headers="headers"
          :items="data"
          :items-per-page="$dataTableConfig.getItemsPerPage()"
          :footer-props="$dataTableConfig.getFooterProps()"
          :loading-text="$dataTableConfig.getLoadText()"
          :loading="loadingDataTable"
      >
        <template v-slot:progress>
          <v-progress-linear
              absolute
              indeterminate
              color="green"
          />
        </template>

        <template v-slot:[`item.producao.sigla`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="text-short mx-auto">{{ item.producao.sigla }}</span>
            </template>
            <span class="">{{ item.producao.sigla }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.destino`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="text-short mx-auto">{{ item.cidade }} - {{ item.estado }}</span>
            </template>
            <span class="">{{ item.cidade }} - {{ item.estado }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.distancia`]="{ item }">
          <span class="num_margin">{{ item.distancia }}</span>
        </template>

        <template v-slot:[`item.inicio_vigencia`]="{ item }">
          <span class="num_margin">{{ $stringFormat.formatDate(item.inicio_vigencia) }}</span>
        </template>

        <template v-slot:[`item.fim_vigencia`]="{ item }">
          <span class="num_margin">{{ $stringFormat.formatDate(item.fim_vigencia) }}</span>
        </template>

        <template v-slot:[`item.quantidade_minima`]="{ item }">
          <span class="num_margin">{{ item.quantidade_minima }}</span>
        </template>

        <template v-slot:[`item.margem_fracionado`]="{ item }">
          <span class="num_margin">{{ item.margem_fracionado | formatNumber }}</span>
        </template>

        <template v-slot:[`item.faixa_1_valor_frete`]="{ item }">
          <span class="num_margin">{{ item.faixa_1_valor_frete }}</span>
        </template>

        <template v-slot:[`item.faixa_1_quantidade`]="{ item }">
          <span class="num_margin">{{ item.faixa_1_quantidade }}</span>
        </template>

        <template v-slot:[`item.faixa_2_valor_frete`]="{ item }">
          <span class="num_margin">{{ item.faixa_2_valor_frete }}</span>
        </template>

        <template v-slot:[`item.faixa_2_quantidade`]="{ item }">
          <span class="num_margin">{{ item.faixa_2_quantidade }}</span>
        </template>

        <template v-slot:[`item.faixa_3_valor_frete`]="{ item }">
          <span class="num_margin">{{ item.faixa_3_valor_frete }}</span>
        </template>

        <template v-slot:[`item.faixa_3_quantidade`]="{ item }">
          <span class="num_margin">{{ item.faixa_3_quantidade }}</span>
        </template>

        <template v-slot:[`item.faixa_4_valor_frete`]="{ item }">
          <span class="num_margin">{{ item.faixa_4_valor_frete }}</span>
        </template>

        <template v-slot:[`item.faixa_4_quantidade`]="{ item }">
          <span class="num_margin">{{ item.faixa_4_quantidade }}</span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <custom-label
              :color="$label.getColor(item.status)"
              :text="$label.getStatusMessage(item.status)"
          />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              small
              @click="editForm(item)"
          >
            mdi-pencil
          </v-icon>
        </template>

        <template v-slot:no-data>
          <div>
            <v-row class="justify-center my-0">
              <v-col class="col-lg-6">
                <h6>Nenhuma Tabela de Frete encontrada para os dados informados</h6>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>

      <!-- Formulário -->
      <v-dialog v-model="dialogForm" max-width="1000">
        <v-card>
          <v-toolbar color="info" dark>
            <span class="headline">{{ titleForm }}</span>
          </v-toolbar>
          <v-form ref="form" lazy-validation>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="col-12 col-lg-6">
                    <EmpresasSelect v-model="empresaForm" obrigatorio/>
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <ProducoesSelect v-model="producaoForm" :empresa="empresaForm" obrigatorio @change="
                        () => {
                        cidadeForm !== '' && estadoForm !== '' ?
                          calculoPendente = true :
                          calculoPendente = false
                          distanciaForm = ''
                        }"/>
                  </v-col>

                  <v-col class="col-12 col-lg-4">
                    <EstadoSelect v-model="estadoForm" return-object></EstadoSelect>
                  </v-col>

                  <v-col class="col-12 col-lg-4">
                    <CidadeSelect v-model="cidadeForm" :estado="estadoForm" return-object @change="codigoIbgeForm = (cidadeForm? cidadeForm.id : '')"></CidadeSelect>
                  </v-col>

                  <v-col class="col-12 col-lg-4">
                    <v-text-field
                        dense
                        outlined
                        label="Código IBGE"
                        v-model="codigoIbgeForm"
                        type="number"
                        :append-icon="codigoIbgeForm !== '' ? 'mdi-magnify' : ''"
                        :rules="codigoIbgeRule"
                        @click:append="getCidadeByCodigoIbge"
                        @change="getCidadeByCodigoIbge"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        label="Distância (km)"
                        v-model="distanciaForm"
                        disabled="true"
                        :rules="distanciaRule"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <v-btn
                        outlined
                        block
                        color="info"
                        class="v-btn--height-fix"
                        :disabled="
                        empresaForm === '' ||
                        producaoForm === '' ||
                        estadoForm === '' ||
                        cidadeForm === '' ||
                        codigoIbgeForm === ''
                      "
                        @click="calcularDistanciaProducaoCidade"
                    >
                      Calcular distância Produção/Cidade
                    </v-btn>
                    <h6 v-if="calculoPendente" class="text-danger text-center">Necessário realizar o cálculo da distância</h6>
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <DatePicker
                        label="Início Vigência"
                        v-model="inicioVigenciaForm"
                        :rules="inicioVigenciaRule"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <DatePicker
                        label="Fim Vigência"
                        v-model="fimVigenciaForm"
                        :rules="fimVigenciaRule"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        label="Faixa 1 Valor (R$)"
                        v-model="faixa1ValorForm"
                        v-money="{ prefix: '' }"
                        :rules="valorFaixa1rule"
                        :maxlength = "12"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        label="Faixa 1 Quantidade (m²)"
                        type="number"
                        v-model="faixa1QtdForm"
                        :rules="qtdFaixa1rule"
                        @keydown="
                        $event.key === '-' ||
                        $event.key === 'e' ||
                        $event.key === '.' ||
                        $event.key === ',' ?
                        $event.preventDefault() : null"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        label="Faixa 2 Valor (R$)"
                        v-model="faixa2ValorForm"
                        v-money="{ prefix: '' }"
                        :rules="valorFaixa2rule"
                        :maxlength = "12"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        label="Faixa 2 Quantidade (m²)"
                        type="number"
                        v-model="faixa2QtdForm"
                        :rules="qtdFaixa2rule"
                        @keydown="
                        $event.key === '-' ||
                        $event.key === 'e' ||
                        $event.key === '.' ||
                        $event.key === ',' ?
                        $event.preventDefault() : null"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        label="Faixa 3 Valor (R$)"
                        v-model="faixa3ValorForm"
                        v-money="{ prefix: '' }"
                        :rules="valorFaixa3rule"
                        :maxlength = "12"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        label="Faixa 3 Quantidade (m²)"
                        type="number"
                        v-model="faixa3QtdForm"
                        @keydown="
                        $event.key === '-' ||
                        $event.key === 'e' ||
                        $event.key === '.' ||
                        $event.key === ',' ?
                        $event.preventDefault() : null"
                        :rules="qtdFaixa3rule"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        label="Faixa 4 Valor (R$)"
                        v-model="faixa4ValorForm"
                        v-money="{ prefix: '' }"
                        :rules="valorFaixa4rule"
                        :maxlength = "12"
                    />
                  </v-col>

                  <v-col class="col-12 col-lg-6">
                    <v-text-field
                        dense
                        outlined
                        reverse
                        label="Faixa 4 Quantidade (m²)"
                        type="number"
                        v-model="faixa4QtdForm"
                        @keydown="
                        $event.key === '-' ||
                        $event.key === 'e' ||
                        $event.key === '.' ||
                        $event.key === ',' ?
                        $event.preventDefault() : null"
                        :rules="qtdFaixa4rule"
                    />
                  </v-col>

                  <v-col class="col-12">
                    <v-switch
                        inset
                        color="success"
                        class="custom-switch"
                        v-model="statusForm"
                        :label="$label.getStatusMessage(statusForm)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                  text
                  color="blue darken-1"
                  @click="dialogForm = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                  text
                  color="blue darken-1"
                  @click="saveForm"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <!-- Upload Tabela de Fretes -->
      <v-dialog v-model="dialogImport" max-width="500">
        <v-card>
          <v-toolbar color="info" dark>
            <span class="headline">Importar Tabela de Fretes</span>
          </v-toolbar>
          <vue-dropzone
              ref="dropzoneRef"
              :options="dropzoneOptions"
              :useCustomSlot="true"
              @vdropzone-file-added="arquivoAdicionado"
              @vdropzone-removed-file="arquivoRemovido"
              @vdropzone-success="importSuccess"
              @vdropzone-error="importErro"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">Arraste para cá um arquivo no formato <strong>xls</strong>, <strong>xlsx</strong> ou <strong>csv</strong></h3>
              <div class="subtitle">ou clique aqui para selecionar um arquivo de seu dispositivo</div>
            </div>
          </vue-dropzone>
          <v-card-actions>
            <v-btn
                text
                small
                color="success"
                @click="downloadTabelaFrete"
            >
              <v-icon>
                mdi-download
              </v-icon>
              Modelo Importação
            </v-btn>
            <v-spacer />
            <v-btn
                text
                color="blue darken-1"
                @click="dialogImport = false"
            >
              Cancelar
            </v-btn>
            <v-btn
                text
                color="blue darken-1"
                @click="importTabelaFretes"
                :disabled="disabledBtnImport"
            >
              <v-icon>
                mdi-upload
              </v-icon>
              Importar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>

import vue2Dropzone from 'vue2-dropzone'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import StorageService from '@/core/services/storage.service'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },

  data: function () {
    return {
      // Filtros
      coordenadas: '',
      empresaFilter: '',
      producaoFilter: '',
      inicioVigenciaFilter: '',
      fimVigenciaFilter: '',
      estadoFilter: '',
      cidadeFilter: '',
      distanciaMinFilter: '',
      distanciaMaxFilter: '',
      statusFilter: 1,
      flagBuscaPendente: false,
      statusOptionsFilter: [
        { status: '', nome: 'Todos' },
        { status: 1, nome: 'Ativo' },
        { status: 0, nome: 'Inativo' },
      ],

      // Dados
      data: [],
      loadingDataTable: false,

      // Formulário
      dialogForm: false,
      actionForm: 'add',

      // validarFaixas
      faixa1Validar: true,
      qtdFaixa1Validar: true,

      valorFaixa2Validar: true,
      qtd2Validar: true,

      valorFaixa3Validar: true,
      qtd3Validar: true,

      valorFaixa4Validar: true,
      qtd4Validar: true,
      msgErrosInputValidate: '',

      areaPlantadaRule: [(v) => (!!v && this.$validations.hasPositiveNumber(this.$stringFormat.convertToFloatNumber(v))) || 'Necessário informar a Área Plantada'],
      empresaRule: [(v) => !!v || 'Selecione uma Empresa'],
      producaoRule: [(v) => !!v || 'Selecione uma Produção'],
      estadoRule: [(v) => !!v || 'Selecione um Estado'],
      cidadeRule: [(v) => !!v || 'Selecione uma Cidade'],
      codigoIbgeRule: [(v) => !!v || 'Informe o código IBGE'],
      distanciaRule: [(v) => !!v || 'Informe os campos acima e clique no botão ao lado'],
      inicioVigenciaRule: [(v) => !!v || 'Informe a Data de Início de Vigência'],
      fimVigenciaRule: [(v) => !!v || 'Informe a Data de Fim de Vigência'],

      valorFaixa1rule: [
        (v) => !(this.convertDecimalStringToFloat(v) === 0) || 'O campo é obrigatório',
      ],
      qtdFaixa1rule: [
        (v) => !!(v) || 'O campo é obrigatório',
      ],

      valorFaixa2rule: [
        (v) => !(parseInt(this.faixa2QtdForm) > 0 && this.convertDecimalStringToFloat(v) === 0) || 'O campo é obrigatório',
        (v) => !(this.faixaValorValidate(v, this.faixa1ValorForm)) || 'O valor deve ser maior que o informado na Faixa 1',
      ],

      qtdFaixa2rule: [
        (v) => !(v && parseInt(v) <= parseInt(this.faixa1QtdForm)) || 'O valor deve ser maior que o informado na Faixa 1',
      ],

      valorFaixa3rule: [
        (v) => !(parseInt(this.faixa3QtdForm) > 0 && this.convertDecimalStringToFloat(v) === 0) || 'O campo é obrigatório',
        (v) => !(this.faixaValorValidate(v, this.faixa2ValorForm)) || 'O valor deve ser maior que o informado na Faixa 2',

      ],

      qtdFaixa3rule: [
        (v) => !(v && parseInt(v) <= parseInt(this.faixa2QtdForm)) || 'O valor deve ser maior que o informado na Faixa 2',
      ],

      valorFaixa4rule: [
        (v) => !(parseInt(this.faixa4QtdForm) > 0 && this.convertDecimalStringToFloat(v) === 0) || 'O campo é obrigatório',
        (v) => !(this.faixaValorValidate(v, this.faixa3ValorForm)) || 'O valor deve ser maior que o informado na Faixa 3',

      ],

      qtdFaixa4rule: [
        (v) => !(v && parseInt(v) <= parseInt(this.faixa3QtdForm)) || 'O valor deve ser maior que o informado na Faixa 3',
      ],

      ufFilter: '',
      tabelaFreteId: '',
      empresaForm: '',
      producaoForm: '',
      estadoForm: '',
      cidadeForm: '',
      codigoIbgeForm: '',
      distanciaForm: '',
      inicioVigenciaForm: '',
      fimVigenciaForm: '',
      faixa1ValorForm: 0,
      faixa1QtdForm: '',
      faixa2ValorForm: 0,
      faixa2QtdForm: '',
      faixa3ValorForm: 0,
      faixa3QtdForm: '',
      faixa4ValorForm: 0,
      faixa4QtdForm: '',
      statusForm: true,

      calculoPendente: false,

      // Upload Tabela de Fretes
      dialogImport: false,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/tabela-frete-importar`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${StorageService.getToken()}`,
          'Cache-Control': '',
        },
        autoProcessQueue: false,
        maxFilesize: 2,
        maxFiles: 1,
        maxfilesexceeded: function (file) {
          this.removeAllFiles()
          this.addFile(file)
        },
        acceptedFiles: '.xls, .xlsx, .csv',
        disablePreviews: true,
        addRemoveLinks: true,
        dictRemoveFile: 'Remover',
        dictCancelUpload: 'Cancelar',
      },
      disabledBtnImport: true,
    }
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Fretes', route: 'fretes' },
      { title: 'Tabela de Fretes' },
    ])

    this.getTabelaFretes()
  },

  computed: {
    headers () {
      return [
        { text: 'Origem', value: 'producao.sigla', class: 'custom-table--header' },
        { text: 'Destino', value: 'destino', class: 'custom-table--header' },
        { text: 'Distância (km)', value: 'distancia', class: 'custom-table--header', align: 'right' },
        { text: 'Início Vigência', value: 'inicio_vigencia', class: 'custom-table--header', align: 'right' },
        { text: 'Fim Vigência', value: 'fim_vigencia', class: 'custom-table--header', align: 'right' },
        { text: 'Qtd. Mín. (m²)', value: 'quantidade_minima', class: 'custom-table--header', align: 'right' },
        { text: 'Margem Frac. (%)', value: 'margem_fracionado', class: 'custom-table--header', align: 'right' },
        { text: 'Faixa 1 (R$)', value: 'faixa_1_valor_frete', class: 'custom-table--header', align: 'right' },
        { text: 'Faixa 1 (m²)', value: 'faixa_1_quantidade', class: 'custom-table--header', align: 'right' },
        { text: 'Faixa 2 (R$)', value: 'faixa_2_valor_frete', class: 'custom-table--header', align: 'right' },
        { text: 'Faixa 2 (m²)', value: 'faixa_2_quantidade', class: 'custom-table--header', align: 'right' },
        { text: 'Faixa 3 (R$)', value: 'faixa_3_valor_frete', class: 'custom-table--header', align: 'right' },
        { text: 'Faixa 3 (m²)', value: 'faixa_3_quantidade', class: 'custom-table--header', align: 'right' },
        { text: 'Faixa 4 (R$)', value: 'faixa_4_valor_frete', class: 'custom-table--header', align: 'right' },
        { text: 'Faixa 4 (m²)', value: 'faixa_4_quantidade', class: 'custom-table--header', align: 'right' },
        { text: 'Status', value: 'status', class: 'custom-table--header' },
        { value: 'actions', sortable: false, class: 'custom-table--header' },
      ]
    },

    titleForm () {
      return this.actionForm === 'add' ? 'Adicionar Tabela de Frete' : 'Editar Tabela de Frete'
    },

  },

  watch: {
    distanciaForm: function (bool) {
      if (bool) {
        this.calculoPendente = false
      }
    },

    distanciaMinFilter: function (val) {
      if (this.distanciaMaxFilter !== '') {
        const min = parseInt(val)
        const max = parseInt(this.distanciaMaxFilter)

        if (min > max) {
          this.distanciaMinFilter = ''
        }
      }

      if (this.producaoFilter) {
        this.flagBuscaPendente = true
      }
    },

    dialogForm: function (bollean) {
      if (!bollean) {
        this.resetForm()
        this.actionForm = 'add'
      }
    },

    dialogImport: function (bollean) {
      if (!bollean) {
        this.$refs.dropzoneRef.removeAllFiles(true)
        this.disabledBtnImport = true
      }
    },
  },

  methods: {
    fieldsSelected () {
      this.flagBuscaPendente = true
    },
    getTabelaFretes () {
      this.loadingDataTable = true
      this.flagBuscaPendente = false
      this.data = []
      const params = {
        empresa_id: this.empresaFilter,
        producao_id: this.producaoFilter,
        inicio_vigencia: this.inicioVigenciaFilter,
        fim_vigencia: this.fimVigenciaFilter,
        uf: this.estadoFilter,
        codigo_ibge: this.cidadeFilter,
        distancia_min: this.distanciaMinFilter,
        distancia_max: this.distanciaMaxFilter,
        status: this.statusFilter.toString(),
      }

      ApiService
        .get('/tabela-frete-filtro', '?' + this.$stringFormat.queryParamsRecursive(params))
        .then((res) => {
          const tabelas = res.data.data

          if (tabelas.length > 0) {
            this.loadingDataTable = false
            this.data = tabelas
          } else {
            this.loadingDataTable = false
            this.data = []
          }
        })
        .catch((error) => {
          this.$refs.snackbar.show(
            'Erro',
            'Não foi carregar as Tabela de Fretes',
            'danger',
          )

          console.error('Erro: ', error)
          this.loadingDataTable = false
          this.data = []
        })
    },

    getCidadeByCodigoIbge () {
      this.calculoPendente = true

      ApiService
        .query(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${this.codigoIbgeForm}`)
        .then((res) => {
          if (res.data.length === 0) {
            this.$refs.snackbar.show(
              'Código IBGE não encontrado',
              'A busca pode ser feita também informando UF e Cidade',
              'alert',
            )

            return
          }

          this.estadoForm = res.data.microrregiao.mesorregiao.UF.id
          this.cidadeForm = res.data.id
        })
    },

    calcularDistanciaProducaoCidade () {
      this.calculoPendente = false
      this.distanciaForm = 'Calculando...'

      ApiService
        .get(`/producao-calcula-distancia-producao-destino/${this.empresaForm}/${this.producaoForm}/${this.cidadeForm?.nome}, ${this.estadoForm?.sigla}`)
        .then((res) => {
          this.distanciaForm = res.data.data
        })
        .catch((error) => {
          this.$refs.snackbar.show(
            'Erro',
            'Não foi possível calcular a distância entre Produção/Cidade',
            'danger',
          )

          this.distanciaForm = ''
          console.error('Erro: ', error)
        })
    },

    async editForm (item) {
      this.actionForm = 'edit'
      this.dialogForm = true
      this.calculoPendente = false
      this.tabelaFreteId = item.id
      this.empresaForm = item.empresa_id
      this.producaoForm = item.producao_id
      this.codigoIbgeForm = item.codigo_ibge
      this.distanciaForm = item.distancia
      this.inicioVigenciaForm = item.inicio_vigencia
      this.fimVigenciaForm = item.fim_vigencia
      this.faixa1ValorForm = item.faixa_1_valor_frete
      this.faixa1QtdForm = item.faixa_1_quantidade === null ? '' : item.faixa_1_quantidade
      this.faixa2ValorForm = item.faixa_2_valor_frete
      this.faixa2QtdForm = item.faixa_2_quantidade === null ? '' : item.faixa_2_quantidade
      this.faixa3ValorForm = item.faixa_3_valor_frete
      this.faixa3QtdForm = item.faixa_3_quantidade === null ? '' : item.faixa_3_quantidade
      this.faixa4ValorForm = item.faixa_4_valor_frete
      this.faixa4QtdForm = item.faixa_4_quantidade === null ? '' : item.faixa_4_quantidade
      this.statusForm = item.status

      this.getCidadeByCodigoIbge()
    },

    msgAlert (msg, type = 'alert') {
      return this.$refs.snackbar.show(
        '',
        msg,
        type,
      )
    },

    faixaValorValidate (faixaValorAtual, faixaValorAcima) {
      if (faixaValorAtual !== '0,00') {
        if (this.convertDecimalStringToFloat(faixaValorAtual) <= this.convertDecimalStringToFloat(faixaValorAcima)) {
          return true
        }
      }
    },
    convertDecimalStringToFloat (valor) {
      const newValue = valor.split('.')
        .join('')
        .split(',')
        .join('.')
      return parseFloat(newValue)
    },

    faixaQuantidadeValidate (faixaQtdAtual, faixaQtdAcima, valorFrete) {
      if (valorFrete !== '0,00') {
        if (faixaQtdAtual === '') {
          return true
        }

        return parseInt(faixaQtdAtual) <= parseInt(faixaQtdAcima)
      }
    },

    saveForm () {
      // this.validarValorFaixa()

      if (this.$refs.form.validate()) {
        if (
          this.faixa1QtdForm === '' &&
          this.faixa2QtdForm === '' &&
          this.faixa3QtdForm === '' &&
          this.faixa4QtdForm === ''
        ) {
          this.msgAlert('Necessário ao menos preencher uma Faixa de Frete')
          return
        }

        if (this.inicioVigenciaForm !== '' && this.fimVigenciaForm !== '') {
          const dataInicio = new Date(this.inicioVigenciaForm)
          const dataFim = new Date(this.fimVigenciaForm)

          if (dataInicio > dataFim) {
            this.$refs.snackbar.show(
              '',
              'Início da Vigência não pode ser maior que o Fim da Vigência',
              'alert',
            )

            return
          }
        }

        this.dialogForm = false
        const params = {
          empresa_id: this.empresaForm,
          producao_id: this.producaoForm,
          estado: this.estadoForm.sigla,
          cidade: this.cidadeForm.nome,
          codigo_ibge: this.codigoIbgeForm,
          distancia: this.distanciaForm,
          inicio_vigencia: this.inicioVigenciaForm,
          fim_vigencia: this.fimVigenciaForm,
          faixa_1_valor_frete: this.faixa1ValorForm === '0,00' ? '' : this.faixa1ValorForm,
          faixa_1_quantidade: this.faixa1QtdForm,
          faixa_2_valor_frete: this.faixa2ValorForm === '0,00' ? '' : this.faixa2ValorForm,
          faixa_2_quantidade: this.faixa2QtdForm,
          faixa_3_valor_frete: this.faixa3ValorForm === '0,00' ? '' : this.faixa3ValorForm,
          faixa_3_quantidade: this.faixa3QtdForm,
          faixa_4_valor_frete: this.faixa4ValorForm === '0,00' ? '' : this.faixa4ValorForm,
          faixa_4_quantidade: this.faixa4QtdForm,
          status: this.statusForm,
        }

        if (this.actionForm === 'add') {
          ApiService
            .post('/tabela-frete', params)
            .then((res) => {
              if (res.status === 200) {
                this.$refs.snackbar.show(
                  '',
                  res.data.data,
                  'alert',
                )
              } else {
                this.getTabelaFretes()

                this.$refs.snackbar.show(
                  'Sucesso',
                  'Tabela de Frete adicionada com sucesso',
                  'success',
                )
              }
            })
            .catch((error) => {
              this.$refs.snackbar.show(
                'Erro',
                'Não foi possível adicionar a Tabela de Frete',
                'danger',
              )

              console.error('Erro: ', error)
            })
        } else {
          ApiService
            .put(`/tabela-frete/${this.tabelaFreteId}`, params)
            .then(() => {
              this.getTabelaFretes()

              this.$refs.snackbar.show(
                'Sucesso',
                'Tabela de Frete alterada com sucesso',
                'success',
              )
            })
            .catch((error) => {
              this.$refs.snackbar.show(
                'Erro',
                'Não foi possível alterar a Tabela de Frete',
                'danger',
              )

              console.error('Erro: ', error)
            })
        }
      }
    },

    resetForm () {
      this.$refs.form.resetValidation()
      this.empresaForm = ''
      this.producaoForm = ''
      this.estadoForm = ''
      this.cidadeForm = ''
      this.codigoIbgeForm = ''
      this.distanciaForm = ''
      this.inicioVigenciaForm = ''
      this.fimVigenciaForm = ''
      this.faixa1ValorForm = 0
      this.faixa1QtdForm = ''
      this.faixa2ValorForm = 0
      this.faixa2QtdForm = ''
      this.faixa3ValorForm = 0
      this.faixa3QtdForm = ''
      this.faixa4ValorForm = 0
      this.faixa4QtdForm = ''
      this.statusForm = true
    },
    async exportFile () {
      if (this.data.length > 0) {
        const url = '/relatorios/exportar'

        const filtros = {
          empresa:
              this.empresaFilter > 0 ? this.empresaFilter : '0',

          producao:
              this.producaoFilter.toString() ? this.producaoFilter.toString() : '0',

          inicio_vigencia:
              this.inicioVigenciaFilter !== '' ? this.inicioVigenciaFilter : '0',

          fim_vigencia:
              this.fimVigenciaFilter !== '' ? this.fimVigenciaFilter : '0',

          cidade:
              this.cidadeFilter !== '' ? this.cidadeFilter : '0',

          uf:
              this.ufFilter !== '' ? this.ufFilter : '0',

          distanciaMinKm:
              this.distanciaMinFilter !== '' ? this.distanciaMinFilter : '0',

          distanciaMaxKm:
              this.distanciaMaxFilter !== '' ? this.distanciaMaxFilter : '0',

          frete_status:
              this.statusFilter.toString(),
        }

        const params = {
          relatorio: 'TabelaDeFretes',
          tipo: 'Excel',
          parametros: JSON.stringify(filtros),
        }

        this.$refs.snackbar.show('Gerando o arquivo', 'Aguarde alguns segundos para baixar o arquivo', 'success', -1, true)

        ApiService.post(url, params).then((res) => {
          if (res.data.data.arquivo !== '') {
            this.$refs.snackbar.show('Arquivo gerado com sucesso', '', 'success')
            window.open(res.data.data.arquivo, '_blank')
          } else {
            this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          }
        }).catch((error) => {
          this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          console.error('Erro: ', error)
        })
      } else {
        this.$refs.snackbar.show('Erro', 'Não foram encontrados dados para exportação!', 'danger')
      }
    },

    downloadTabelaFrete () {
      window.open('/media/docs/tabela-fretes-exemplo.xlsx', '_blank')
    },

    arquivoAdicionado () {
      this.disabledBtnImport = false
    },

    arquivoRemovido () {
      this.disabledBtnImport = true
    },

    importTabelaFretes () {
      this.$refs.snackbar.show(
        'Importando Arquivo',
        'Aguarde alguns segundos para importação da Tabela de Fretes',
        'success',
        -1,
        true,
      )

      this.$refs.dropzoneRef.processQueue()
    },

    importSuccess () {
      this.dialogImport = false

      this.$refs.snackbar.show(
        'Tabela de Frete enviada para fila de importação',
        'Após o processo ser finalizado as informações serão enviadas ao setor de logística',
        'success',
        8000,
      )
    },

    importErro (file) {
      this.disabledBtnImport = true
      this.$refs.dropzoneRef.removeAllFiles(true)

      if (file.accepted) {
        this.$refs.snackbar.show(
          'Não foi possível realizar a importação',
          '',
          'danger',
          4000,
        )

        return
      }

      if (file.size > 2000 && file.type === 'application/vnd.ms-excel') {
        this.$refs.snackbar.show(
          'Arquivo excede tamanho permitido',
          'Tamanho máximo do arquivo deve ser de 2mb',
          'alert',
          4000,
        )

        return
      }

      this.$refs.snackbar.show(
        'Arquivo no formato incorreto',
        'Só pode ser adicionado um arquivo no formato xls/xlsx/csv',
        'alert',
        4000,
      )
    },
    /*
    validarValorFaixa () {
      this.faixa1Validar = true
      this.qtdFaixa1Validar = true

      this.valorFaixa2Validar = true
      this.qtdFaixa2Validar = true

      this.valorFaixa3Validar = true
      this.qtdFaixa3Validar = true

      this.valorFaixa4Validar = true
      this.qtdFaixa4Validar = true

      if (this.faixa1ValorForm === '0,00') {
        this.faixa1Validar = false
        this.msgErrosInputValidate = 'Valor da faixa 1 é obrigátorio'
        return
      }

      if (this.faixa1QtdForm === '') {
        this.qtdFaixa1Validar = false
        this.msgErrosInputValidate = 'Valor da Quatidade da faixa 1 é obrigátorio'
      }

      if (this.faixaValorValidate(this.faixa2ValorForm, this.faixa1ValorForm)) {
        this.valorFaixa2Validar = false
        this.msgErrosInputValidate = 'O valor da faixa atual deve ser maior que o valor das faixas anteriores'

        return
      }

      if (this.faixaValorValidate(this.faixa3ValorForm, this.faixa2ValorForm)) {
        this.valorFaixa3Validar = false
        this.msgErrosInputValidate = 'O valor da faixa atual deve ser maior que o valor das faixas anteriores'

        return
      }

      if (this.faixaValorValidate(this.faixa4ValorForm, this.faixa3ValorForm)) {
        this.valorFaixa4Validar = false
        this.msgErrosInputValidate = 'O valor da faixa atual deve ser maior que o valor das faixas anteriores'
      }

      if (this.faixaQuantidadeValidate(this.faixa2QtdForm, this.faixa1QtdForm, this.faixa2ValorForm)) {
        this.qtdFaixa2Validar = false
        this.msgErrosInputValidate = 'A quantidade da faixa atual deve ser maior que o valor das faixas anteriores'

        return
      }

      if (this.faixaQuantidadeValidate(this.faixa3QtdForm, this.faixa2QtdForm, this.faixa3ValorForm)) {
        this.qtdFaixa3Validar = false
        this.msgErrosInputValidate = 'A quantidade da faixa atual deve ser maior que o valor das faixas anteriores'
        return
      }

      if (this.faixaQuantidadeValidate(this.faixa4QtdForm, this.faixa3QtdForm, this.faixa4ValorForm)) {
        this.qtdFaixa4Validar = false
        this.msgErrosInputValidate = 'A quantidade da faixa atual deve ser maior que o valor das faixas anteriores'
      }
    },
    */
  },
}
</script>

<style>
  .custom-switch,
  .custom-switch .v-input__slot,
  .custom-switch label {
    padding: 0 !important;
    margin: 0 !important;
  }
  .custom-switch .v-input--selection-controls__ripple {
    display: none !important;
  }
  .vue-dropzone {
    display: flex;
    justify-content: center;
  }
  .dropzone .dz-preview {
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-around !important;
    padding: 10px;
    width: 150px;
  }
  .dropzone .dz-preview .dz-details {
    background-color: #15844a;
    border-radius: 5px;
    background-image: url('/media/logos/logo-excel.png');
    background-position: center 10px;
    background-size: 40px;
  }
  .dropzone .dz-preview .dz-details .dz-size,
  .dropzone .dz-preview .dz-details .dz-filename,
  .dropzone .dz-preview .dz-success-mark,
  .dropzone .dz-preview .dz-error-mark {
    display: none !important;
  }
  .dropzone .dz-preview .dz-error-message {
    top: 0;
  }
  .dropzone .dz-preview .dz-image {
    height: 35px;
    width: 0;
    background: none;
    border: none;
    border-radius: 0 !important;
  }
  .dropzone .dz-preview .dz-progress {
    position: initial;
    left: initial;
    top: initial;
    width: 80px;
    height: 10px;
    border-radius: 5px;
    margin: 0 auto;
  }
  .dropzone .dz-preview .dz-remove {
    position: initial !important;
    margin-left: 0 !important;
    display: flex !important;
    justify-content: center !important;
    box-sizing: border-box !important;
    opacity: 1 !important;
    border: none;
    letter-spacing: 0;
    padding: 0;
  }
  .custom-table--header-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
</style>
